import { useState } from 'react';
import { Link } from 'react-router-dom';
import TopRibbon from "../components/TopRibbon";
import CurrencyLabel from '../components/CurrencyLabel';
import Loading from "../components/Loading";
import NotAuthorized from '../components/NotAuthorized';
import "./MyCart.css";

function MyCart({ isAuthenticated, cart, removeFromCart, setCart }) {
  const [isLoading, setIsLoading] = useState(false);
  const disclaimerText = <div>He leido y estoy de acuerdo con los terminos y condiciones del servicio estipulados en <Link to="/tyc"><b>Terminos y Condiciones del Servicio.</b></Link></div>
  const [statusMessage, setStatusMessage] = useState("Recuerda leer los terminos y condiciones de los eventos y hacer click en 'Sí, estoy de acuerdo'");
  const [agreed, setAgreed] = useState(false);
  const [viewPaymentOptions, setViewPaymentOptions] = useState(false);
  const [url, setUrl] = useState("");
  const [token, setToken] = useState("");
  const [wasClickedOnce, setWasClickedOnce] = useState(false);
  const [currency, setCurrency] = useState("CLP");
  const rates = {
    "USD": Number(process.env.REACT_APP_USD_TO_CLP),
  };

  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

  function getTotalPrice() {
    if (!cart || cart.length === 0) {
      return 0;
    }

    let totalPrice = 0;
    for (const event of cart) {
      totalPrice += event.price;
    }

    return totalPrice;
  };

  function convert(amount, currency) {
    if (amount <= 0) {
        return 0;
    }

    if (currency === "CLP") {
      return amount;
    } else {
      return (amount / rates[currency]);
    }
    
  };

  function handleClick(e) {
    e.preventDefault();
    if (currency === "CLP") {
        setCurrency("USD");
    } else {
        setCurrency("CLP");
    }
  };

  const eventTypes = {
    "individual-class": "Clase Individual",
    "group-class": "Clase Grupal",
    "coaching-session": "Coaching",
    "other": "Actividad"
  }

  async function verifyCart(e) {
    if (wasClickedOnce === true) {
      return
    }

    if (isLoading === true) {
      return
    }

    if (cart.length === 0) {
      setStatusMessage("No hay eventos agendados en el carro");
      return;
    }

    if (cart.length > 6) {
      setStatusMessage("Maximo 6 eventos por compra");
      return;
    }

    const newSet = new Set();

    for (const event of cart) {
      if (newSet.has(event._id)) {
        setStatusMessage(`Hay eventos repetidos en tu carro: ${event.type} ${event.date.toLocaleDateString()}. Porfavor elimina uno de ellos para continuar.`);
        return;
      }
      newSet.add(event._id);
    }

    setIsLoading(true);

    const events = cart.map((event) => event._id);
    const jwtToken = sessionStorage.getItem("jwtToken");
    const request = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`
      },
      body: JSON.stringify({
        events: events,
      }),
    }
    const result = await fetch(`${process.env.REACT_APP_API_URL}/purchaseEvents`, request);
    const response = await result.json();
    
    if (result.status === 200) {
      setUrl(response.transaction.url);
      setToken(response.transaction.token);
      setStatusMessage("Selecciona método de pago.");
      setWasClickedOnce(true);
      setViewPaymentOptions(true);
    } else {
      setStatusMessage(response.message);
    }
    setIsLoading(false);
  }

  function restartPaymentConfirmation() {
    setViewPaymentOptions(false);
    setUrl("");
    setToken("");
    setWasClickedOnce(false);
    setAgreed(false);
    setStatusMessage("Recuerda leer los terminos y condiciones de los eventos y hacer click en 'Sí, estoy de acuerdo'");
  }

  function checkboxOnChange(e) {
    if (!e.target.checked) {
      restartPaymentConfirmation();
    }
    else {
      setAgreed(true);
    }
  }

  function handleDeleteClick(event) {
    const checkbox = document.getElementById("tos");
    removeFromCart(event);
    restartPaymentConfirmation();
    checkbox.checked = false;
  }

  return (
    <>
    <TopRibbon title="Compras" />
    <div className="my-cart--container">
        {!isAuthenticated ? <NotAuthorized /> : (
            <div className="my-cart--body fade-in-up">
                <div className="my-cart--title">
                  <div className="my-cart--title-text">
                    Mis compras
                    <div className="my-cart--title-seal">
                      {cart.length}
                    </div>
                  </div>
                  <button className="my-cart--title-close" onClick={() => {
                    setCart([]);
                    restartPaymentConfirmation();
                    }}>
                    <img src="/trash.svg" alt="Eliminar compras" width={30} height={30} />
                  </button>
                </div>
                <div className="my-cart--events">
                  <div className="my-cart--events-headers">
                    <div className="my-cart--events-header"></div>
                    <div className="my-cart--events-header">Fecha</div>
                    <div className="my-cart--events-header">Hora</div>
                    <div className="my-cart--events-header">Descripción</div>
                    <div className="my-cart--events-header">Precio</div>
                  </div>
                  {cart.map((event, index) => {
                    const eventDate = new Date(event.date);
                    return(
                      <div className="my-cart--event" key={index}>
                        <button className="my-cart--event-delete" onClick={() => handleDeleteClick(event)}>
                          {"X"}
                        </button>
                        <div className="my-cart--event-date">
                          {eventDate.toLocaleDateString()}
                        </div>
                        <div className="my-cart--event-time">
                        {`${eventDate.getHours()}:${eventDate.getMinutes() === 0 ? '00' : eventDate.getMinutes()}`}
                        </div>
                        <div className="my-cart--event-desc">
                          {eventTypes[event.type]}
                        </div>
                        <CurrencyLabel
                          css={"my-cart--event-pricing"}
                          amount={convert(event.price, currency)}
                          currency={currency}
                          priceFormatter={priceFormatter}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="my-cart--submit">
                  <div className="my-cart--submit-details">
                    <div className="my-cart--submit-details-total">
                      Total:
                    </div>
                    <button className="my-cart--submit-details-btn" onClick={(e) => handleClick(e)}>
                      <div className="my-cart--submit-details-currency">
                        {currency === "USD" ? "CLP" : "USD"}
                      </div>
                      <CurrencyLabel
                        css={"my-cart--submit-details-pricing"}
                        amount={convert(getTotalPrice(), currency)}
                        currency={currency}
                        priceFormatter={priceFormatter}
                      />
                    </button>
                  </div>
                  <div className="my-cart--submit-status-bar">
                    {isLoading ? <Loading /> : (
                      <div className="my-cart--submit-status-bar--content">
                        <img src="/sign.svg" alt="Recordatorio" width={40} height={40} />
                        <p>{statusMessage}</p>
                      </div>
                    )}
                  </div>
                  <div className="my-cart--submit-disclaimer">
                    {disclaimerText}
                  </div>
                  <div className="my-cart--submit-checkbox" >
                    <input type="checkbox" id="tos" name="tos" onChange={checkboxOnChange} />
                    <label htmlFor="tos">
                      Sí, estoy de acuerdo.
                    </label>
                  </div>
                  <button className="my-cart--submit-btn" onClick={verifyCart} id="confirm-btn" disabled={(!agreed || wasClickedOnce)}>Confirmar</button>
                </div>
            </div>
        )}
      {(agreed && viewPaymentOptions) && 
        <div className="payment--options-body">
          <div>Elige una forma de pago:</div>
          <div className="payment--options-method">
            <div className="payment--options-transbank-img"></div>
            <form method="post" action={url}>
              <input type="hidden" name="token_ws" value={token} />
              <input type="submit" value="Webpay Plus" className="my-cart--submit-btn option-btn" />
            </form>
            <div className="payment--options-text">*Tarjeta de Crédito Internacional O Débito Chile</div>
          </div>
        </div>}
    </div>
    </>
  )
}

export default MyCart;